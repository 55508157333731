import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  input,
  effect,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbTypeahead,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, OperatorFunction, Observable, merge } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';

const NO_MATCH_FOUND = 'No match found';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTypeahead,
    TranslateModule,
  ],
  selector: 'designage-typeahead',
  template: `<input
      *ngIf="!inputDisabled"
      type="search"
      class="form-control form-control-dark"
      [class.form-control-sm]="formSizeSmall"
      [ngbTypeahead]="search"
      (focus)="focus$.next($any($event).target.value)"
      (click)="click$.next($any($event).target.value)"
      (selectItem)="onSelectItem($event)"
      placeholder="{{ placeHolderText | translate }}"
      [(ngModel)]="item"
      [ngModelOptions]="{ standalone: true }"
      #typeaheadInstance="ngbTypeahead"
    />

    <input
      *ngIf="inputDisabled"
      class="form-control form-control-dark"
      [class.form-control-sm]="formSizeSmall"
      [disabled]="true"
      [value]="item"
    /> `,
  styleUrls: ['./typeahead.component.scss'],
})
export class TypeaheadComponent {
  manualItem = input<string>('');
  @ViewChild('typeaheadInstance') typeaheadInstance!: NgbTypeahead;
  @Input() items!: string[];
  @Input()
  set defaultItem(value: string) {
    this.item = value;
  }
  @Input() placeHolderText?: string;
  @Input() noMatchedItemText!: string;
  @Input() formSizeSmall?: boolean;

  // using the var name "formControl" causes a bug, changed to "control"
  @Input() control: FormControl = new FormControl();
  @Input() inputDisabled: boolean = false;
  @Output() selectItem = new EventEmitter<string>();
  @Output() noMatchedItem = new EventEmitter<string>();

  item!: string;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor() {
    // effect(() => console.log('manualItem', this.manualItem()));
  }

  /**
   * It is possible to get the focus events with the current input value to emit results on focus.
   * A search is done no matter the content of the input:
   * - on empty input all options will be taken
   * - otherwise options will be filtered against the search term
   * Reference: https://ng-bootstrap.github.io/#/components/typeahead
   */
  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.typeaheadInstance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) => this.getMatchedItems(term))
    );
  };

  setItem(item: string) {
    this.item = item;
    this.control.setValue(item);
  }

  getMatchedItems(term: string): string[] {
    this.item = term?.trim();
    const matchedItems =
      term === ''
        ? this.items
        : this.items.filter(
            (item) =>
              item?.trim()?.toLowerCase().indexOf(term?.trim()?.toLowerCase()) >
              -1
          ) || [];

    if (!matchedItems.length && this.noMatchedItemText) {
      matchedItems.push(`${NO_MATCH_FOUND}: ${this.noMatchedItemText}`);
    }

    return matchedItems;
  }

  onSelectItem(event: NgbTypeaheadSelectItemEvent) {
    if (event.item.indexOf(NO_MATCH_FOUND) === 0) {
      this.noMatchedItem.emit(this.item);
    } else {
      this.item = event.item;
      this.selectItem.emit(this.item);
    }
  }
}
